import React from "react";
import BlockContent from "../sanity/block-content";
import styles from "./blockContentSection.module.css";
import { ContainerComponentResponsive } from "../container";

function BlockContentSection(props) {
  const { tagline } = props;

  const containerVariableStyles = {
    backgroundColor: (props.bgcolor && props.bgcolor.hex) || "transparent",
    padding: (props.padding && props.padding) || "0px",
    maxWidth: (props.maxwidth && props.maxwidth) || "1220px",
    margin: (props.margin && props.margin) || "auto",
    borderRadius: (props.borderradius && props.borderradius) || "0px",
    width: "100%",
  };

  return (
    <ContainerComponentResponsive>
      <div style={containerVariableStyles}>
        <section className={styles.blockContentSection}>
          <BlockContent blocks={tagline} />
        </section>
      </div>
    </ContainerComponentResponsive>
  );
}

export default BlockContentSection;
