import React from "react";
import BlockContent from "../sanity/block-content";
import CTALink from "../CTALink";
import styles from "./hero.module.css";

import { imageUrlFor } from "../../lib/image-url";
import { buildImageObj } from "../../lib/helpers";
import { ContainerComponent } from "../container";


function Hero(props) {
  return (
    <div style={{backgroundColor: (props.bgcolor && props.bgcolor.hex) || "transparent"}}>
      <div style={{color: (props.textcolor && props.textcolor.hex) || "black"}}>
        <ContainerComponent>
        <div className={styles.wrapper}>
          {/* Left col */}
          <div className={styles.leftCol}>
            <p className={styles.leftColLabel}>{props.label}</p>
            <h1 className={styles.leftColHeading}>{props.heading}</h1>
            <div className={styles.leftColPortableText}>
              <BlockContent blocks={props.tagline} />
            </div>
            <div className={styles.ctaGrid}>
              {(props.ctas || []).map((c, i) => (
                <CTALink key={`cta_${i}`} {...c} buttonActionClass={styles.button}/>
              ))}
            </div>
          </div>

          {/* Right col */}
          <div className={styles.rightCol}>
            {props.mainImage && props.mainImage.asset && (
            <img
              src={imageUrlFor(buildImageObj(props.mainImage))
                .width('1500')
                .auto("format")
                .url()}
              alt={props.mainImage.alt}
            />
            )}
          </div>
        </div>
        </ContainerComponent>
      </div>
    </div>
  );
}

export default Hero;
