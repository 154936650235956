import React, { useState } from "react";
import axios from "axios";
import { ContainerComponent } from "../container";
import styles from "./contactForm.module.css";

import BlockContent from "../sanity/block-content";
import { imageUrlFor } from "../../lib/image-url";
import { buildImageObj } from "../../lib/helpers";

function ContactForm(props) {
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  });
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    });
    if (ok) {
      form.reset();
    }
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true });
    axios({
      method: "post",
      url: "https://getform.io/f/8f5ef70c-d7ee-43d9-b33b-c1a70c11a54f",
      data: new FormData(form),
    })
      .then((r) => {
        handleServerResponse(true, "Takk! Meldingen er sendt.", form);
      })
      .catch((r) => {
        handleServerResponse(false, r.response.data.error, form);
      });
  };

  return (
    <div className={styles.wrapper}>
      <div
        style={{
          backgroundColor:
            (props.bgcolor && props.bgcolor.hex) || "transparent",
        }}
      >
        <div
          style={{ color: (props.textcolor && props.textcolor.hex) || "black" }}
        ></div>
        <ContainerComponent>
          <h3>{props.heading}</h3>
          <form onSubmit={handleOnSubmit}>
            <div className={styles.formGroup}>
              <label
                className={styles.label}
                for="inputEmail"
                required="required"
              >
                E-postadresse
              </label>
              <input
                type="email"
                name="email"
                className={styles.formControl}
                id="inputEmail"
                aria-describedby="emailHelp"
                placeholder="Skriv inn e-postadresse"
                required="required"
              />
            </div>
            <div className={styles.formGroup}>
              <label className={styles.label} for="inputName">
                Navn
              </label>
              <input
                type="text"
                name="name"
                className={styles.formControl}
                id="inputName"
                placeholder="Skriv inn navn"
                required="required"
              />
            </div>
            <div className={styles.formGroup}>
              <label className={styles.label} for="inputMessage">
                Melding
              </label>
              <textarea
                type="text"
                name="message"
                className={styles.formControl}
                id="inputMessage"
                placeholder="Skriv inn melding"
                required="required"
              />
            </div>

            <div className={styles.formGroup}>
              <label className={styles.label} for="formControlSelect">
                Velg tjenester
              </label>
             


{props.categories && (
              <div>
                <div>
                  {props.categories.map(i => (
                  <div key={i._id} className={styles.formCheckInline}>


                        <input type="hidden" name="services[]" value="0"></input>
                              <input
                                className={styles.formCheckInput}
                                name="services[]"
                                type="checkbox"
                                id={i.name}
                                value={i.name}
                              ></input>
                              <label
                                className="form-check-label"
                                name="services[]"
                                for={i.name}
                              >
                                {i.name || <em>Missing name</em>} 
                              </label>
                              <div className={styles.description}>
                                <BlockContent blocks={i.description} />
                              </div>


                              <div className={styles.imgWrapper}>
                                {i && i.image && i.image.asset && (
                                  <img className={styles.img}
                                    src={imageUrlFor(buildImageObj(i.image))
                                      .width(250)
                                      .height(250)
                                      .fit('crop')
                                      .url()}
                                    alt=''
                                  />
                                )}
                        </div>


                  </div>
                  ))}
                </div>
              </div>
            )}

            </div>

            {serverState.status && (
              <p
                style={{
                  marginTop: "1rem",
                  padding: "1rem",
                  borderWidth: "2px",
                  background: 'white',
                }}
                className={!serverState.status.ok ? "errorMsg" : ""}
              >
                {serverState.status.msg}
              </p>
            )}

            <button
              type="submit"
              className={styles.formButton}
              disabled={serverState.submitting}
            >
              Send
            </button>

          </form>
        </ContainerComponent>
      </div>
    </div>
  );
}

export default ContactForm;
